<template>
  <template
    v-if="
      isConfirmation &&
      !onboardStore.ctaShown &&
      amount > 0 &&
      guestStore.dependents
    "
  >
    <div
      class="bg-purples-dark absolute right-10 top-12 z-[100] block h-3 w-4"
      style="clip-path: polygon(0% 100%, 50% 0%, 100% 100%)"
    />
    <div
      class="bg-purples-dark absolute right-3 top-14 z-30 flex w-full max-w-xs gap-2 rounded-lg p-4 text-white lg:right-auto lg:max-w-sm"
    >
      <div class="flex flex-col">
        <div class="flex items-center justify-between">
          <div class="font-semibold leading-6">
            You still have credit to use!
          </div>
          <button
            class="self-start"
            aria-label="Close"
            lang="en"
            @click.stop="handleDismiss()"
          >
            <ZenniIconsIconClose class="text-white" size="base" />
          </button>
        </div>
        <div class="text-sm leading-5 tracking-[0.015em]">
          Don't miss out on getting new glasses for yourself or your family.
          <nuxt-link to="/products/list" class="underline">Shop now.</nuxt-link>
        </div>
      </div>
    </div>
  </template>
  <template
    v-if="guestStore.dependents && !onboardStore.ctaShown && !isConfirmation"
  >
    <div
      class="bg-purples-dark absolute right-10 top-12 z-[100] block h-3 w-4"
      style="clip-path: polygon(0% 100%, 50% 0%, 100% 100%)"
    />
    <div
      class="bg-purples-dark absolute right-3 top-14 z-30 flex w-full max-w-xs gap-2 rounded-lg p-4 text-white lg:right-auto lg:max-w-sm"
    >
      <div class="flex flex-col">
        <span class="font-semibold leading-6">
          <template v-if="guestStore.dependents.length">
            Switch profiles
          </template>
          <template v-else-if="guestStore.dependents.length === 0">
            You have {{ formattedAmount }} to spend
          </template>
        </span>
        <span class="font-base mt-0.5 text-sm leading-5 tracking-[0.015em]">
          <template v-if="guestStore.dependents.length">
            Choose who you’re getting glasses for and see how much credit you
            have left to spend. The credit will be automatically applied in your
            cart.</template
          >
          <template v-else-if="guestStore.dependents.length === 0">
            We’ve given you {{ formattedAmount }} to spend on safety glasses.
            The credit will be automatically applied in your cart.
          </template>
        </span>
      </div>
      <button
        class="self-start"
        aria-label="Close"
        lang="en"
        @click.stop="handleDismiss()"
      >
        <ZenniIconsIconClose class="text-white" size="base" />
      </button>
    </div>
  </template>
</template>

<script setup lang="ts">
import { formatCurrency } from '@/utils/helper/formatCurrency'
import { computed, toRefs, useBreakpoints, watchEffect } from '#imports'
import { useGuestStore } from '~/store/guest'
import { useOnboardStore } from '~/store/onboard'

const props = defineProps<{
  isConfirmation: boolean
}>()

const { isConfirmation } = toRefs(props)
const { isMobile } = useBreakpoints()

const guestStore = useGuestStore()
const amount = computed<number>(() =>
  guestStore.currentGift
    ? guestStore.calculateGiftAmt(guestStore.currentGift)
    : 0,
)
const formattedAmount = computed<string>(() =>
  formatCurrency(amount.value, 'en-US', {
    currency: 'USD',
    minimumFractionDigits: 2,
  }),
)

const onboardStore = useOnboardStore()
const handleDismiss = () => {
  onboardStore.setCtaShown(true)
}

watchEffect(() => {
  if (isMobile.value && !onboardStore.ctaShown) {
    setTimeout(() => {
      handleDismiss()
    }, 5000)
  }
})
</script>
